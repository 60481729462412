@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */
a {
	@apply text-blue-700;
}

a:hover {
	@apply text-blue-800;
}

/* Start purging... */
@tailwind utilities;

/* Your own custom utilities */
